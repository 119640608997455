import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import ColorPriceComponent from '../components/wx_store/color_price';

const ColorPriceManagePage = ({ location }) => (
  <SiteLayout>
    <SEO title="WX ColorPrice" />
    <ColorPriceComponent location={location}></ColorPriceComponent>
  </SiteLayout>
)

export default ColorPriceManagePage;
